import React, { useEffect, useState } from "react";
import {
  ICategory,
  IProduct,
  IProductResponse,
} from "../../interfaces/product";
import {
  fetchCategoriesById,
  fetchRecommendedProducts,
} from "../../redux/api/productApi";
import { Link, useNavigate } from "react-router-dom";
import ProductGrid from "../productGrid/ProductGrid";
import { useAppDispatch } from "../../redux/hooks";
import { setSelectedCategory } from "../../redux/slices/productSlice";
import "./ProductDetail.scss";
import {
  addCommentToProduct,
  getProductComments,
} from "../../redux/api/commentsApi";
import { Comment } from "../../interfaces/comment";
import { Rating } from "react-simple-star-rating";
import { getInstallmentOptions } from "../../redux/api/paymentApi";
import { InstallmentTable } from "../installmentTable/InstallmentTable";
import { getCompatibleVehicles } from "../../redux/api/vehicleApi";
import SelectedVehicle from "../selectedVehicle/SelectedVehicle";
import { IMyVehicle } from "../../interfaces/garage";
import RefundPolicy from "../refundPolicy/RefundPolicy";

interface ProductDetailProps {
  product: IProduct;
  addToCart: (product: IProduct) => void;
}

export default function ProductDetail(props: ProductDetailProps) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { product, addToCart } = props;
  const [category, setCategory] = React.useState<ICategory | undefined>();
  const [relatedProductsResponse, setRelatedProductsResponse] = React.useState<
    IProduct[]
  >([]);
  const [showImageIdx, setShowImageIdx] = React.useState(0);
  const [selectedProductDetailTab, setSelectedProductDetailTab] =
    React.useState("description");
  const [productComments, setProductComments] = useState<Array<Comment>>([]);
  const [commentText, setCommentText] = useState("");
  const [rate, setRate] = useState(5);
  const [avageRate, setAvageRate] = useState(5);
  const [installmentOptions, setInstallmentOptions] = useState<any[]>([]);
  const [compatibleVehicles, setCompatibleVehicles] = useState<any[]>([]);

  const addComment = () => {
    // add
    addCommentToProduct({
      text: commentText,
      rate: rate,
      productId: product.id,
    });
  };

  useEffect(() => {
    if (!product.categoryId) {
      return;
    }
    fetchCategoriesById(product.categoryId + "").then((response) => {
      setCategory(response);
    });
    fetchRecommendedProducts("" + product?.id).then((response) => {
      setRelatedProductsResponse(response);
    });
    getProductComments("" + product?.id).then((response) => {
      setProductComments(response);
      const totalRate = response.reduce(
        (acc: any, comment: any) => acc + comment.rating,
        0,
      );
      setAvageRate(response.length === 0 ? 0 : totalRate / response.length);
    });
    getInstallmentOptions(product.id).then((response) => {
      setInstallmentOptions(response);
    });
    getCompatibleVehicles(product.id).then((response) => {
      setCompatibleVehicles(response);
    });
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [product.id]);
  return (
    <section className="shop-details p_relative pt_140 pb_80">
      <div className="auto-container">
        <div className="product-details-content p_relative d_block mb_100">
          <div className="row clearfix">
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <div className="image-box shope-details-left-image p_relative d_block">
                {product.imageUrls?.map((image, index) => (
                  <figure
                    className={`image${showImageIdx === index ? "-active" : ""}`}
                    key={index}
                  >
                    <img src={image} alt="parts" />
                    <div className="preview-link p_absolute t_20 r_20">
                      <a
                        href={image}
                        className="lightbox-image p_relative d_iblock fs_20 centred z_1 w_50 h_50 color_black lh_50"
                        data-fancybox="gallery"
                      >
                        <i className="far fa-search-plus"></i>
                      </a>
                    </div>
                  </figure>
                ))}
                {
                  // image next and prev button
                }
                <div className="image-nav d-flex justify-content-around">
                  <button
                    className="prev-btn fs_30 color_black"
                    onClick={() => {
                      if (showImageIdx === 0) {
                        return;
                      }
                      setShowImageIdx(showImageIdx - 1);
                    }}
                  >
                    <i className="flaticon-left-arrow"></i>
                  </button>
                  <button
                    className="next-btn fs_30 color_black"
                    onClick={() => {
                      if (
                        product &&
                        product.imageUrls &&
                        showImageIdx === product.imageUrls.length - 1
                      ) {
                        return;
                      }
                      setShowImageIdx(showImageIdx + 1);
                    }}
                  >
                    <i className="flaticon-right-arrow"></i>
                  </button>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="product-details p_relative d_block ml_20">
                <h2 className="d_block fs_30 lh_40 fw_sbold font_family_inter mb_5">
                  {product.name}
                </h2>
                <div className="customer-rating clearfix p_relative d_block mb_5">
                  <ul className="rating clearfix">
                    {Array.from({ length: 5 }, (_, index) => (
                      <li
                        key={index}
                        className={`p_relative d_iblock pull-left mr_3 fs_13`}
                      >
                        <i
                          className={`fas fa-star${index < avageRate ? "" : " empty-star"}`}
                        ></i>
                      </li>
                    ))}
                    <li className="p_relative d_iblock pull-left fs_14">
                      <div className="color_black">
                        ({avageRate !== 0 ? avageRate.toFixed(2) : "Yorum Yok"})
                      </div>
                    </li>
                  </ul>
                </div>
                <span className="p_relative d_block fs_20 lh_30 fw_medium color_black mb_25 font_family_inter">
                  {product.price?.toFixed(2)} TL
                </span>
                <div className="text p_relative d_block mb_30">
                  <p className="font_family_poppins mb_25">
                    {product.description}
                  </p>
                  <p className="font_family_poppins">{product.excerpt}</p>
                </div>
                <div className="addto-cart-box p_relative d_block mb_35">
                  <ul className="clearfix">
                    <li className="p_relative d_block float_left mr_10">
                      {product.stockStatus === "InStock" && (
                        <button
                          type="button"
                          className="theme-btn theme-btn-eight"
                          onClick={() => addToCart(product)}
                        >
                          Sepete Ekle
                        </button>
                      )}
                      {product.stockStatus === "OutOfStock" && (
                        <div>
                          <button
                            type="button"
                            className="btn-two"
                            disabled
                          >
                            Stokta Yok
                          </button>
                        </div>
                      )}
                    </li>
                    <li className="p_relative d_block float_left mr_10">
                      <button
                        className="d_iblock p_relative fs_20 lh_50 w_50 h_50 centred b_radius_5"
                      >
                        <i className="flaticon-heart"></i>
                      </button>
                    </li>
                  </ul>
                </div>
                <div className="other-option">
                  <ul className="list">
                    <li className="p_relative d_block fs_16 font_family_poppins mb_5">
                      <span className="fw_medium color_black">Stok kodu:</span>{" "}
                      {product.url?.split("-p-")[1]}
                    </li>
                    <li className="p_relative d_block fs_16 font_family_poppins mb_5">
                      <span className="fw_medium color_black">Marka:</span>{" "}
                      {product.brand.name}
                    </li>
                    <li className="p_relative d_block fs_16 font_family_poppins mb_5">
                      <span className="fw_medium color_black">Category:</span>{" "}
                      <button
                        className="btn btn-link"
                        onClick={() => {
                          if (!category) {
                            return;
                          }
                          dispatch(setSelectedCategory(category));
                          navigate("/categories/" + category.url);
                        }}
                      >
                        {category?.name}
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="product-discription p_relative d_block mb_80">
          <div className="tabs-box">
            <div className="tab-btn-box p_relative d_block mb_35">
              <ul className="tab-btns d-flex justify-content-center flex-wrap tab-buttons clearfix">
                <li
                  className="tab-btn mt-3 active-btn p_relative d_iblock fs_18 font_family_inter lh_20 float_left fw_medium z_1 mr_35 tran_5"
                  onClick={() => setSelectedProductDetailTab("description")}
                >
                  Açıklama
                </li>
                <li
                  className="tab-btn mt-3 p_relative d_iblock fs_18 font_family_inter lh_20 float_left fw_medium z_1 tran_5"
                  onClick={() => setSelectedProductDetailTab("comments")}
                >
                  Yorumlar ({productComments.length})
                </li>
                <li
                  className="tab-btn mt-3 p_relative d_iblock fs_18 font_family_inter ml-3 lh_20 float_left fw_medium z_1 tran_5"
                  onClick={() => setSelectedProductDetailTab("installments")}
                >
                  Taksitler
                </li>
                <li
                  className="tab-btn mt-3 p_relative d_iblock fs_18 font_family_inter ml-3 lh_20 float_left fw_medium z_1 tran_5"
                  onClick={() => setSelectedProductDetailTab("compatible-cars")}
                >
                  Uyumlu Araçlar
                </li>
                <li
                  className="tab-btn mt-3 p_relative d_iblock fs_18 font_family_inter ml-3 lh_20 float_left fw_medium z_1 tran_5"
                  onClick={() =>
                    setSelectedProductDetailTab("return-conditions")
                  }
                >
                  İade koşulları
                </li>
              </ul>
            </div>
            <div className="tabs-content">
              {
                // description tab
                selectedProductDetailTab === "description" && (
                  <div className="tab active-tab">
                    <div className="content-box">
                      <p className="font_family_poppins mb_25">
                        {product.description}
                      </p>
                    </div>
                  </div>
                )
              }
              {
                // comments tab
                selectedProductDetailTab === "comments" && (
                  <div className="tab active-tab">
                    <div className="content-box">
                      <h4 className="p_relative d_block fs_20 lh_30 fw_medium fw_sbold mb_25">
                        Yorumlar ({productComments.length})
                      </h4>
                      {productComments.map((comment, index) => (
                        <div
                          className="comment-box p_relative d_block pl_110 mb-3"
                          key={index}
                        >
                          <figure className="comment-thumb p_absolute l_0 t_0 w_80 h_80 b_radius_55">
                            <img
                              src={"/assets/images/blank-profile-picture.webp"}
                              alt="parts"
                            />
                          </figure>
                          <h5 className="d_block fs_18 lh_20 fw_sbold">
                            {comment.userName}
                            <span className="d_iblock fs_16 font_family_poppins">
                              {" "}
                              -{" "}
                              {new Date(
                                comment?.createdAt,
                              ).toLocaleDateString()}
                            </span>
                          </h5>
                          <ul className="rating clearfix mb_15">
                            {Array.from({ length: 5 }, (_, i) => (
                              <li
                                key={i}
                                className={`p_relative d_iblock pull-left mr_3 fs_13 ${
                                  i < comment.rating ? "" : "empty-star"
                                }`}
                              >
                                <i className="fas fa-star"></i>
                              </li>
                            ))}
                          </ul>
                          <div className="text">
                            <p className="fs_15 font_family_poppins">
                              {comment.text}
                            </p>
                          </div>
                          <hr></hr>
                        </div>
                      ))}
                    </div>
                    <div className="customer-comment-input col-6">
                      <h4 className="p_relative d_block fs_20 lh_30 fw_medium fw_sbold mb_25 mb-3">
                        Yorum Yap
                      </h4>
                      <Rating
                        className="mb-3"
                        onClick={(rate) => {
                          // rate
                          setRate(rate);
                        }}
                      />
                      <input
                        type="text"
                        placeholder="Yorumunuz"
                        className="form-control mb-3"
                        value={commentText}
                        onChange={(e) => setCommentText(e.target.value)}
                      />
                      <button
                        className="theme-btn theme-btn-eight"
                        onClick={() => addComment()}
                      >
                        Yorum Yap
                      </button>
                    </div>
                  </div>
                )
              }
              {
                // installments tab
                selectedProductDetailTab === "installments" && (
                  <div className="tab active-tab mt-1">
                    <div className="content-box">
                      <h4 className="p_relative d_block fs_20 lh_30 fw_medium fw_sbold mb_25">
                        Taksitler
                      </h4>
                    </div>
                    {installmentOptions && installmentOptions.length > 0 && (
                      <InstallmentTable data={installmentOptions} />
                    )}
                  </div>
                )
              }
              {
                // compatible cars tab
                selectedProductDetailTab === "compatible-cars" && (
                  <div className="tab active-tab mt-1">
                    <div className="content-box">
                      <h4 className="p_relative d_block fs_20 lh_30 fw_medium fw_sbold mb_25">
                        Uyumlu Araçlar
                      </h4>
                    </div>
                    {compatibleVehicles &&
                      compatibleVehicles.length > 0 &&
                      compatibleVehicles.map((cv: any) => (
                        <SelectedVehicle
                          key={cv.id}
                          myVehicle={{ vehicle: cv } as IMyVehicle}
                        />
                      ))}
                  </div>
                )
              }
              {
                // return conditions tab
                selectedProductDetailTab === "return-conditions" && (
                  <div className="tab active-tab mt-1">
                    <div className="content-box">
                      <RefundPolicy />
                    </div>
                  </div>
                )
              }
            </div>
          </div>
        </div>
        {(relatedProductsResponse?.length || 0) !== 0 && (
          <div className="related-product shop-page-2 shop-page-section">
            <div className="title-text mb_25">
              <h2 className="d_block fs_30 lh_40 fw_sbold">İlgili Ürünler</h2>
            </div>
            <div className="row clearfix">
              <ProductGrid
                categoryInactive
                pagingActive
                groupedSize={4}
                productList={
                  {
                    items: relatedProductsResponse,
                    totalCount: relatedProductsResponse.length,
                  } as IProductResponse
                }
              />
            </div>
          </div>
        )}
      </div>
    </section>
  );
}
