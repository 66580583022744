import React from "react";
import { IOrderDetail } from "../../interfaces/order";
import "./OrderDetail.scss";

interface OrderDetailProps {
  order: IOrderDetail;
}

const OrderDetail: React.FC<OrderDetailProps> = ({ order }) => {
  return (
    <div className="order-detail-card card">
      <div className="card-header">
        <h5 className="order-title">Sipariş {order.id}</h5>
        <p className="order-status">
          Sipariş {new Date(order.createdAt).toLocaleDateString()} tarihinde oluşturuldu ve şu anda{" "}
          <mark>{order.status}</mark> durumunda.
        </p>
      </div>
      <div className="card-body">
        <div className="order-items">
          <table className="order-table">
            <thead>
              <tr>
                <th>Ürün</th>
                <th>Toplam</th>
              </tr>
            </thead>
            <tbody>
              {order.orderItems.map((item, index) => (
                <tr key={index}>
                  <td>
                    {item.product.name} × {item.quantity}
                  </td>
                  <td>{(item.product.price * item.quantity).toFixed(2)} TL</td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <th>Ara Toplam</th>
                <td>{order.totalPrice.toFixed(2)} TL</td>
              </tr>
              <tr>
                <th>Kargo({order.shippingPrice.shippingCompany})</th>
                <td>{order.shippingPrice.price.toFixed(2)} TL</td>
              </tr>
              <tr>
                <th>Toplam</th>
                <td>{(order.totalPrice + order.shippingPrice.price).toFixed(2)} TL</td>
              </tr>
            </tfoot>
          </table>
        </div>
        <div className="addresses">
          <div className="address billing-address">
            <h6>Fatura Adresi</h6>
            <p>{order.billingAddress.name}</p>
            <p>{order.billingAddress.addressText}</p>
            <p>Telefon: {order.billingAddress.phone}</p>
            <p>Email: {order.billingAddress.email}</p>
          </div>
          <div className="address shipping-address">
            <h6>Kargo Adresi</h6>
            <p>{order.shippingAddress.name}</p>
            <p>{order.shippingAddress.addressText}</p>
            <p>Telefon: {order.shippingAddress.phone}</p>
            <p>Email: {order.shippingAddress.email}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetail;
